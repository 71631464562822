'use strict';

module.exports = {
	showAppointmentModal: function () {
		$('body').on('click', '#btn-appointment', function (event) {
			event.preventDefault();
			var $this = $(this);
			var url = $this.data('updateStatisticUrl');

			var pageDesignerCustomLink = $this.data('page-designer-custom-link');
			if (pageDesignerCustomLink) {
				window.location.href = pageDesignerCustomLink;
				return;
			}

			$.ajax({
				url: url,
				type: 'GET'
			});

			$('#appointment-modal').modal('toggle');
		});
	},
	submitAppointmentModal: function () {
		$('body').on('submit', '#appointment-form', function (event) {
			event.preventDefault();
			$('#appointmentModal-message').empty();
			var form = $('#appointment-form');
			let clientSideValidation = require('./clientSideValidation');
			clientSideValidation.functions.validateForm(form, event);

			$.ajax({
				url: form.attr('action'),
				type: 'POST',
				data: form.serialize(),
				success: function (response) {
					var responseHtml;
					if (response.error) {
						responseHtml = '<div class="alert alert-danger alert-dismissible'
							+ 'fade show" role="alert">'
							+ '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
							+ '<span aria-hidden="true">&times;</span>'
							+ '</button> ' + response.msg + ' </div>';
						$('#appointmentModal-message').append(responseHtml);
						$('#appointment-modal').animate({ scrollTop: 0 }, 'fast');
					} else if (response.redirectUrl) {
						window.location.href = response.redirectUrl;
					} else {
						$('#appointment-modal').modal('toggle');
					}
				},
				error: function (err) {
					var errorHtml = '<div class="alert alert-danger alert-dismissible'
						+ 'fade show" role="alert">'
						+ '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
						+ '<span aria-hidden="true">&times;</span>'
						+ '</button> Error: ' + err.responseJSON.message() + ' </div>';
					$('#appointmentModal-message').append(errorHtml);
					$('#appointment-modal').scrollTop();
					$('#appointment-modal').animate({ scrollTop: 0 }, 'fast');
				}
			});
		});
	}
};
